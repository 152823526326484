<template>
    <layout-default>
        <section class="hero hero-bright bg-cover bg-top"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/loesungen_banner.jpg') + ')' }">
            <div class="container z-index-20 mt-lg-5">
                <div class="row align-items-lg-center gy-4">
                    <div class="col-lg-7 order-2 order-lg-1">
                        <h1 class="text-uppercase text-white">Unsere <span class="text-success">Lösungen</span></h1>
                        <p class="lead text-white mb-0">Mehr als nur ein Therapiezentrum</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="pb-5">
            <div class="container">
                <div class="row gy-5" href="#vortrag" id="vortrag">
                    <leistung-vortrag />
                </div>
            </div>
        </section>
        <section class="bg-light pb-5">
            <div class="container">
                <div class="row gy-5" href="#theraplus" id="theraplus">
                    <leistung-app />
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import LeistungVortrag from '../components/Leistungen/LeistungVortrag.vue';
import LeistungApp from '../components/Leistungen/LeistungApp.vue';

export default {
    name: 'Leistungen',
    components: {
        LayoutDefault,
        LeistungVortrag,
        LeistungApp
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
